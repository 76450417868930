import React, { useState } from "react"
import { navigate } from "gatsby"

import { Container, Box, TextField, InputAdornment, Typography } from '@mui/material';
import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded';
import CheckCircleOutlineRounded from '@mui/icons-material/CheckCircleOutlineRounded';

import Layout from "../components/layout"
import Seo from "../components/seo"
import FormHeader from "../components/formHeader"
import SendButton from "../components/sendButton"
import ErrorDialog from "../components/errorDialog"

export default function Certification() {

    const [sendingError, setSendingError] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [inputError, setInputError] = useState({
        email: true,
    });

    const [haveEdited, setHaveEdited] = useState({
        email: false,
    });

    const [inputData, setInputData] = useState({
        email: "",
    });

    const entrykey = {
        email: "entry.1917383485",
    }

    const closeSendingError = () => {
        setSendingError(false);
    }

    const handleChackChange = (name, event, pattern) => {
        setHaveEdited(({ ...haveEdited, [name]: true }));
        var constraints = {
            [name]: [pattern]
        }
        var constraint = new RegExp(constraints[name][0]);
        if (constraint.test(event.target.value)) {
            setInputError({ ...inputError, [name]: false });
        } else {
            setInputError({ ...inputError, [name]: true });
        }
    };

    const ValidationIcon = (inputErrorName) => {
        if (!inputError?.[inputErrorName.inputErrorName] && haveEdited?.[inputErrorName.inputErrorName]) {
            return (<CheckCircleOutlineRounded color="success" />);
        } else if (haveEdited?.[inputErrorName.inputErrorName]) {
            return (<ErrorOutlineRounded color="error" />);
        } else {
            return (<></>);
        }
    }

    const changeHaveEditedToTrue = () => {
        setHaveEdited({ ...haveEdited, "email": true });
    }

    const checkSubmission = async (event) => {
        setButtonDisabled(true);
        changeHaveEditedToTrue();
        const submitter = event.nativeEvent.submitter;
        event.preventDefault();
        if (submitter === null) {
            setButtonDisabled(false);
            return false;
        } else if (submitter.id !== "sendButton") {
            setButtonDisabled(false);
            return false;
        } else {
            let formData = "";
            const entry = Object.keys(entrykey);
            for (let index = 0; index < entry.length; index++) {
                formData += `${entrykey?.[entry[index]]}=${inputData?.[entry[index]]}`;
                if (index < entry.length - 1) {
                    formData += "&";
                }
            }
            const myInit = {
                method: 'POST',
                mode: "cors",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': "text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8",
                },
                redirect: 'follow',
                body: encodeURI(formData)
            };
            await fetch(`https://secure-headland-84331.herokuapp.com/https://docs.google.com/forms/d/e/1FAIpQLSc1tjTP647FGzeoZZZNjzEE7-u15SWIndQrh-hg066jaIxbUQ/formResponse`, myInit)
                .then(response => {
                    const result = response;
                    console.log(result.status);
                    if (!result.ok) {
                        console.error('サーバーエラー');
                    } else {
                        navigate("/submitted", { state: { title: "メール認証", message: "メール認証を開始しました。ご入力のメールアドレスに会員申込みに必要な事項を登録するメールを送信いたしますので、ご確認ください。もしメールが半日以内に届かない場合には、contact@techfeelo.orgまでご連絡ください。" } })
                    }
                })
                .catch(() => {
                    console.log('エラー');
                    setSendingError(true);
                    setButtonDisabled(false);
                })
        }
    }

    return (
        <Layout>
            <Seo title="会員申込み" robotsType="noindex" />
            <FormHeader currentStep={1} />
            <Container component="main" maxWidth="xs" style={{ marginTop: "3%", marginBottom: "5%" }}>
                <Typography variant="h3">メール認証</Typography>
                <Typography variant="body2">入力されたメールアドレスに登録フォームへのリンクをお送りします。</Typography>

                {/* フォーム */}
                <Box
                    component="form"
                    onSubmit={(event) => { checkSubmission(event); }}
                    id="myForm" name="myForm"
                >
                    <TextField
                        margin="normal" variant="outlined" fullWidth autoFocus={true}
                        label="メールアドレス *" name="entry.1917383485" id="emailForm"
                        autoComplete="email"
                        onChange={e => {
                            handleChackChange("email", e, "^[a-zA-Z0-9][a-zA-Z0-9_+-.]+@[a-zA-Z0-9][a-zA-Z0-9-.]*\\.[a-zA-Z.]*[a-zA-Z]+$");
                            setInputData({ ...inputData, email: e.target.value });
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <ValidationIcon inputErrorName="email" />
                                </InputAdornment>
                            )
                        }}
                        error={inputError.email && haveEdited.email}
                        helperText="hogehoge@fugafuga.piyo の形式で入力してください。"
                        InputLabelProps={{ shrink: true }}
                    />
                    <SendButton changeHaveEditedToTrue={changeHaveEditedToTrue} inputError={inputError} haveEdited={haveEdited} disabled={buttonDisabled} />
                </Box>
                <ErrorDialog open={sendingError} closeSendingError={closeSendingError} />
            </Container>
        </Layout>
    );
}